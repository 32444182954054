body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.styled-card-element {
	border-radius: 10px !important;
	border: 1px solid #bcd46c;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 7px;
}

/*@media (prefers-color-scheme: dark) {
  body {
      background-color: white !important; 
      color: rgba(0, 0, 0, 0.87) !important;
  }
}*/

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.styled-alert {
	border-radius: 20px !important;
	border-bottom: 3px solid #bcd46c;
}
